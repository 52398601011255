<template>
  <modal
    :width="900"
    :adaptive="true"
    class="moreDetailModal CEModal modal-scroll-bar d-flex"
    name="moreDetailModal"
  >
    <div class="h-100">
      <button class="button modal_close" @click="$emit('closeModal')">
        <img src="/assets/img/close.svg" alt />
      </button>
      <div class="d-flex m-b-20">
        <button
          class="primary-btn primary-btn_sm m-0"
          :class="currentTab == 'tab2' ? 'inactive' : ''"
          @click="currentTab = 'tab1'"
          v-if="isGeneralRule"
        >
          {{ $t('TOURISM_PRODUCT.GENERAL_RULES') }}
        </button>
        <button
          class="primary-btn primary-btn_sm mx20"
          :class="currentTab == 'tab1' ? 'inactive' : ''"
          @click="currentTab = 'tab2'"
          v-if="isCancellationData"
        >
          {{ $t('CANCELLATION.CANCELLATION_DETAILS') }}
        </button>
      </div>
      <div class="more-details-block fs14 d-flex">
        <div
          v-if="currentTab == 'tab1'"
          class="overflow-auto"
          v-html="generalRules"
        ></div>
        <div
          v-if="currentTab == 'tab2'"
          class="overflow-auto"
          v-html="cancellationDetails"
        ></div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'MoreDetail',
  data() {
    return {
      currentTab: 'tab1',
      cancellationDetails: '',
      generalRules: '',
      isCancellationData: true,
      isGeneralRule: true,
    };
  },
  computed: {
    ...mapState({
      setting_data: (state) => state.setting.general_setting,
    }),
  },
  async created() {
    if (this.setting_data == '') {
      await this.get_generalSetting();
    }

    if (this.setting_data != '') {
      if (this.setting_data.nofshon_cancellation_details) {
        this.cancellationDetails =
          this.setting_data.nofshon_cancellation_details;
        this.isCancellationData = true;
      } else if (this.setting_data.nofshon_cancellation_details_default) {
        this.cancellationDetails =
          this.setting_data.nofshon_cancellation_details_default;
        this.isCancellationData = true;
      } else {
        this.isCancellationData = false;
        this.currentTab = 'tab1'
      }

      if (this.setting_data.nofshon_general_rules) {
        this.generalRules = this.setting_data.nofshon_general_rules;
        this.isGeneralRule = true;
      } else if (this.setting_data.nofshon_general_rules_default) {
        this.generalRules = this.setting_data.nofshon_general_rules_default;
        this.isGeneralRule = true;
      } else {
        this.isGeneralRule = false;
        this.currentTab = 'tab2'
      }
    }
  },
  methods: {
    ...mapActions('setting', {
      get_generalSetting: 'get_generalSetting',
    }),
  },
};
</script>

<style lang="scss">
.moreDetailModal {
  .vm--modal {
    padding: 30px 50px;
    overflow: hidden !important;
  }
}
</style>
<style lang="scss" scoped>
.moreDetailModal {
  .vm--modal {
    padding: 30px 50px;
    top: 20% !important;
  }
  .more-details-block {
    background-color: #f5f5f5;
    padding: 20px 10px;
    height: calc(100% - 36px);
  }
  .modal_close {
    position: absolute;
    top: 10px;
    left: 15px;
    cursor: pointer;
    img {
      width: 15px;
    }
  }
  .primary-btn.inactive {
    background-color: #f5f5f5;
    color: #002036;
  }
}

.ltr-type {
  .moreDetailModal {
    .modal_close {
      left: unset;
      right: 15px;
    }
  }
}
</style>